import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { withQuoteWizard } from '../components/withQuoteWizard';

import {
  TurfVariatiesPage,
  TifTufPage,
  SirWalterPage,
  SirGrangePage,
  NullarborPage,
} from '../imageURLs';

import SEO from '../components/SEO';

import GreenCta from '../components/GreenCta';
import Post from '../components/Post/Post';

const styles = theme =>
  createStyles({
    root: {},
  });

const TurfVarieties = ({ data, classes, toggleQuoteComp, children }) => (
  <React.Fragment>
    <SEO
      title="Turf Varieties - Twin View Turf - Producer of Australia’s Best Lawns"
      keywords={[
        `Turf`,
        `varieties`,
        `Lawns`,
        `Best Lawns`,
        `Australia’s Best Lawns`,
        `lawn producer`,
      ]}
    />
    <Post
      title="TifTuf"
      subTitle="The allrounder"
      customerName="James Nash"
      customerAddress="Nundah"
      bodyCopy="Our kids love playing on TifTuf in the afternoons after school, it’s great for backyard sports."
      coverImage={TurfVariatiesPage.TifTuf}
      profileImage={TifTufPage.happyCustomer}
      // stickyImg
      // stickyImagePath = { TurfVariatiesPage.waterMark } //
      specHref="/turf-tiftuf"
      toggleQuoteComp={() => toggleQuoteComp('tt')}
      sticky
      // variatesPost
     
    />
    <Post
      title="Sir Walter DNA"
      subTitle="Australia’s #1 buffalo"
      bodyCopy="Sir Walter creates a lush, green space for our growing family – and it's great with our dog too."
      coverImage={TurfVariatiesPage.SirWalter}
      profileImage={SirWalterPage.happyCustomer}
      // stickyImg
      // stickyImagePath = { TurfVariatiesPage.waterMark } //
      specHref="/turf-sir-walter-dna-certified"
      customerName="Tahni Scott"
      customerAddress="Northgate"
      toggleQuoteComp={() => toggleQuoteComp('sw')}
      sticky
      // variatesPost
      
    />
    <Post
      title="Sir Grange"
      subTitle="Pratical luxury"
      customerName="Pam & Mark"
      customerAddress="Bribie Island"
      bodyCopy="I love how good Sir Grange looks with such little effort, we barely mow it!"
      coverImage={TurfVariatiesPage.SirGrange}
      profileImage={SirGrangePage.happyCustomer}
      // stickyImg
      // stickyImagePath = { TurfVariatiesPage.waterMark } //
      specHref="/turf-sir-grange"
      toggleQuoteComp={() => toggleQuoteComp('sg')}
      sticky
      // variatesPost
      
    />
    <Post
      title="Nullarbor Couch"
      subTitle="Great value"
      customerName="Liam Jennings"
      customerAddress="Carseldine"
      bodyCopy="We wanted a budget savvy lawn to sell our house – we loved it so much we got it in our new place."
      coverImage={TurfVariatiesPage.Nullarbor}
      profileImage={NullarborPage.happyCustomer}
      // stickyImg
      // stickyImagePath = { TurfVariatiesPage.waterMark } //
      specHref="/turf-nullarbor-couch"
      toggleQuoteComp={() => toggleQuoteComp('nc')}
      sticky
      // variatesPost
      
    />
    <GreenCta
      ctaTitle="Want to talk with a lawn expert?"
      ctaHref="tel:1800686711"
      ctaText="Call now"
      isExternalLink1={true}
      inverted
    />

    {children}
  </React.Fragment>
);

export default withQuoteWizard(withStyles(styles, { withTheme: true })(TurfVarieties));
