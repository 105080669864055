import React from 'react';
import SiteLink from '../SiteLink';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { UiButton } from '../CTAButton/CTAButton';

import { pxToRem, CenterStyle } from '../../theme';

import TextBlock from '../TextBlock';
import CoverImage from '../Hero/HeroImage';
import Image from '../Image';

const styles = theme =>
  createStyles({
    stickyPost: {
      height: '88vh',
      overflow: 'hidden',
      backgroundColor: theme.palette.common.white,
    },

    variatesPost: {
      height: '120vh',
      overflow: 'hidden',
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up(960)]: {
        height: '88vh'
      },
    },

    coverImageWrap: {
      maxHeight: '25vh',
      // height: '50vh', //
      overflow: 'hidden',
      flex: 1,  
    },
    stickyCoverImageWrap: {
      maxHeight: '60vh',
      // height: '50vh' //
    },
    coverImage: {
      // height: '25vh',
      height: '50vh',  
    },
    stickyCoverImage: {
      height: '60vh', 
    },
    copy: {
      padding: 25,
      backgroundColor: theme.palette.common.white,
      ...CenterStyle,
      position: 'relative',
      [theme.breakpoints.up(960)]: {
        paddingBottom: 45,
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    copyMiddle: {
      padding: '15px 25px',
      backgroundColor: theme.palette.common.white,
      ...CenterStyle,
      position: 'relative',
      [theme.breakpoints.up(960)]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },

    stickyCopy: {
      bottom: 0,
      left: 0,
    },
    profileWrap: {
      marginTop: '-75px',
      [theme.breakpoints.up(400)]: {
        marginTop: '-85px',
      },
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row-reverse',
        marginTop: 0,
      },
    },
    profileWrapMiddle: {
      flexDirection: 'column-reverse',
      marginTop: '-75px',
      [theme.breakpoints.up(960)]: {
        alignItems: 'center',
        marginTop: 0,
      },
      [theme.breakpoints.down(960)]: {
        marginTop: 0,
        flexDirection: 'row',
      },
    },

    profileImage: {
      width: 120,
      height: 120,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: '50%',
      willChange: 'transform',
      border: 0,
      [theme.breakpoints.up(960)]: {
        alignSelf: 'center',
        width: 200,
        height: 200,
      },
      [theme.breakpoints.down(960)]: {
        order: 0,
      }
    },
    profileImageWrap: {
      width: 120,
      textAlign: 'center',
      [theme.breakpoints.up(960)]: {
        width: 200,
        marginTop: -140,
        justifyContent: 'center',
        flexBasis: 'auto',
      }
    },
    profileImageWrapExpanded: {
      width: 320,
      [theme.breakpoints.up(960)]: {
        width: 400,
      }
    },
    quote: {
      textAlign: 'right',
      // paddingRight: 10,
      paddingRight: 18,
      paddingTop: '65px',
      marginRight: 10,
      fontSize: pxToRem(16),
      overflow: 'hidden',
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(20),
        paddingTop: '75px',
        paddingRight: 10
      },
      [theme.breakpoints.up(960)]: {
        textAlign: 'left',
        paddingTop: 15,
      },
    },
    middleQuote: {
      padding: 0,
      margin: '0 auto',
      maxWidth: 300,
      paddingBottom: '30px',
      textAlign: 'center',
    },

    quotePosition: {
      order: 1,
      [theme.breakpoints.up(960)]: {
        order: 0
      },
    },
    customerName: {
      textAlign: 'center',
      paddingTop: 10,
    },
    middleCustomerName: {
      fontSize: '1.5rem',
      marginBottom: 10,
      paddingTop: 25,
      [theme.breakpoints.up(400)]: {
        fontSize: `${theme.typography.enlargePercent(1.5)}rem`,
      },
    },
    largeCta: {
      display: 'flex',
      width: '100%',
      padding: '15px 15px 20px',
      justifyContent: 'center',
      backgroundColor: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      // border: 0,
      fontWeight: 500,
      fontSize: pxToRem(20),
      lineHeight: pxToRem(20),
      textDecoration: 'none',
      marginTop: 20,
      color: theme.palette.common.white,
      borderRadius: 10,
      transition: '0.4s linear',
      border: '1px solid #181A38',//
      '&:hover': {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: 'white', //
        color: '#181A38', //

      },
      [theme.breakpoints.up(400)]: {
        fontSize: pxToRem(28),
        lineHeight: pxToRem(28),
      },
    },
    makeItTight: {
      [theme.breakpoints.up(400)]: {
        paddingTop: 20,
      },
      [theme.breakpoints.up(600)]: {
        maxWidth: 600,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: 40,
      },

    },

    untightIt: {
      maxWidth: 800,
    },

    stickyImgWrap: {
      width: '100%',
      // position: 'fixed',
      position: 'relative',
      textAlign: 'center',
      backgroundColor: 'white',
      bottom: 0,
      left: 0,
      right: 0,
      // zIndex: 33,
      // padding: '5px 0',
      // marginTop: 70,
      marginTop: 30,
      [theme.breakpoints.up(767)]: {
        position: 'relative',
        marginTop: 20
      },

      [theme.breakpoints.up(1340)]: {
        position: 'absolute',
        bottom: '25px',
        left: '10px',
        margin: '0',
        maxWidth: '265px',
        marginTop: 20,
      },
    },

    stickyImage: {
      width: '190px',
      height: 'auto',
      objectFit: 'cover',
      margin: '0 auto',
      [theme.breakpoints.up(370)]: {
        width: '330px',
      },
      [theme.breakpoints.up(767)]: {
        maxWidth: '265px',
        height: 'auto'
      },
    }

  });

const Post = ({
  title,
  subTitle,
  coverImage,
  profileImage,
  stickyImagePath,
  bodyCopy,
  classes,
  specHref = '#',
  sticky = false,
  noSpec = false,
  noQuote = false,
  customerName = 'Mark & Pam',
  customerAddress,
  middle = false,
  untightIt = false,
  toggleQuoteComp,
  makeItHappneForTrade = false,
  quotePosition,
  stickyImg,
  variatesPost

}) => {
  const quoteCopy = (
    <Typography
      variant="body1"
      component="blockquote"
      className={classNames(classes.quote, {
        [classes.middleQuote]: middle,
        [classes.untightIt]: untightIt,
        
      })}
    >
      {`“${bodyCopy}”`}
    </Typography>
  );

  return (
    <Grid
      item
      container
      direction="column"
      className={classNames({ [classes.stickyPost]: sticky }, { [classes.variatesPost]: variatesPost } )}
    >
      {title && subTitle && (
        <Grid item>
          <TextBlock text={title} subtitle={subTitle} />
        </Grid>
      )}
      <Grid
        item
        className={classNames(classes.coverImageWrap, {
          [classes.stickyCoverImageWrap]: sticky,
        })}
      >
        <CoverImage
          relativePath={coverImage}
          className={classNames(classes.coverImage, {
            [classes.stickyCoverImage]: sticky,
          })}
        />
      </Grid>

      <Grid
        item
        container
        className={classNames(middle ? classes.copyMiddle : classes.copy, {
          [classes.stickyCopy]: sticky,
          [classes.makeItTight]: makeItHappneForTrade,
        })}
      >

        <Grid
          item
          container
          direction={middle ? 'column-reverse' : 'row'}
          className={middle ? classes.profileWrapMiddle : classes.profileWrap}
          justify="center"
          md={middle ? 12 : 6}
        >

          <Grid item xs={middle ? 12 : 8} md={5}  
            className={classNames(classes.quoteWrap, {
            [classes.quotePosition]: quotePosition,
          })} >
            {!makeItHappneForTrade && quoteCopy}
          </Grid>
          <Grid
            item
            container
            alignContent="center"
            justify="center"
            xs={middle ? 12 : 4}
            md={6}
          >
            <Grid item container
              alignContent="center"
              className={classNames(classes.profileImageWrap, {
                [classes.profileImageWrapExpanded]: untightIt,
              })}
              md={12}
            >
              <Image
                relativePath={profileImage}
                className={classes.profileImage}
              />
              <Typography
                variant="body1"
                className={classNames(classes.customerName, {
                  [classes.middleCustomerName]: middle,
                })}
              >
                <strong>{customerName}</strong>
                <br />
                {customerAddress}
              </Typography>
              {makeItHappneForTrade && quoteCopy}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column" md={3}>
          {!noSpec && (
            <SiteLink className={classes.largeCta} to={specHref}>
              Get specs
            </SiteLink>
          )}
          {!noQuote && (
            <UiButton onClick={toggleQuoteComp} btnType="wide" inverted>
              <strong>Quick quote</strong>
            </UiButton>
          )}

        </Grid>

        {stickyImg && (
         <div
          className={classes.stickyImgWrap} 
          >
            <Image
              relativePath={stickyImagePath}
              className={classes.stickyImage}
            ></Image>
        </div>
        )} 

      </Grid>



    </Grid>

  );
};

export default withStyles(styles, { withTheme: true })(Post);
