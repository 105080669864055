import React from 'react';
import classNames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CTAButton from './CTAButton/CTAButton';

const styles = theme =>
  createStyles({
    root: {
      padding: 40,
      backgroundColor: '#1BB25F',
    },
    invertedRoot: {
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      paddingBottom: 30,
    },
    button: {
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
  });

const GreenCta = ({
  classes,
  ctaTitle,
  ctaHref,
  isExternalLink1 = false,
  ctaText,
  ctaOnClick,
  ctaState = null,
  ctaText2,
  ctaHref2,
  ctaState2 = null,
  isExternalLink2 = false,
  ctaOnClick2,
  invertedBg = false,//
  inverted,
  theme,
  ...rest
}) => {
  const hasCTA1 = ctaText || ctaHref;
  const hasCTA2 = ctaText2 || ctaHref2;

  return (
    <Grid
      container
      direction="column"
      className={classNames(classes.root, { [classes.invertedRoot]: invertedBg })}
      {...rest}
    >
      <Grid item className={classes.title}>
        <Typography variant="h2" color="secondary">
          {ctaTitle}
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={classes.button}
      >
        {hasCTA1 && (
          <CTAButton
            ctaText={ctaText}
            ctaHref={ctaHref}
            external={isExternalLink1}
            ctaOnClick={ctaOnClick}
            destinationState={ctaState}
            btnStyles={{
              // backgroundColor: inverted ? '#1BB25F' : theme.palette.primary.main,
            }}
           inverted={inverted}
     
          />
        )}

        {hasCTA2 && (
          <CTAButton
            ctaText={ctaText2}
            ctaHref={ctaHref2}
            ctaOnClick={ctaOnClick2}
            destinationState={ctaState2}
            external={isExternalLink2}
            btnStyles={{
              // backgroundColor: inverted ? '#1BB25F' : theme.palette.primary.main,
            }}
            inverted={inverted}
            
    
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(GreenCta);
